import { useContext, useEffect, useState } from "react";
import Current from "./Current";
import Future from "./Future";
import Request from "./Request";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { Grid, Stack, Typography } from "@mui/material";
import ErrorPage from "../../shared/ErrorPage";
import CustomBtn from "../../common/Button";
import { Add } from "@mui/icons-material";
import Create from "./Create";
import useViewportWidth from "../../../utils/useViewportWidth";
import { smallDesktop } from "../../GlobalConstants";
import { useTranslation } from "react-i18next";
import Loader from "../../shared/Loader";
import { getAvailabilityAll } from "../../../features/availabilityAllReducer/action";
import {
  setEditPendingAvailability,
  setIdealShifts,
  setOnHomeScreen,
} from "../../../features/metaReducer";
import Warning from "../../../styles/assets/svg/warning-icon.svg";
import "./style.scss";
import { AnalyticsContext, PageType } from "../../../analytics";

const Availability: React.FC = () => {
  const [currentData, setCurrentData] = useState();
  const [futureData, setFutureData] = useState<any[]>([]);
  const [requestsData, setRequestsData] = useState<any>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [
    isWeeklyRotationalAvailabilityExists,
    setIsWeeklyRotationalAvailabilityExists,
  ] = useState<boolean>(false);

  const { availabilityData, availabilityRequestData, loading, success } =
    useAppSelector((state: any) => state.availabilityAll);

  const { onHomeScreen } = useAppSelector((state: any) => state.meta);

  const windowWidth = useViewportWidth();
  const { t: translate } = useTranslation();
  const dispatch = useAppDispatch();
  const analytics = useContext(AnalyticsContext);

  useEffect(
    () =>
      analytics?.trackPageView({
        name: "My Availability",
        pageType: PageType.Document,
      }),
    [],
  );

  useEffect(() => {
    const empId = Number(localStorage.getItem("userEmployeeId"));
    if (success || !empId) {
      return;
    }
    dispatch(setOnHomeScreen(true));
    dispatch(getAvailabilityAll())
      .then((action) => {
        const response = action.payload;
        if (response.data && response.errorResponse === null) {
          setCurrentData(
            response?.data.availabilityResponse.currentAvailability !== null
              ? response?.data.availabilityResponse.currentAvailability
              : null,
          );
          setFutureData(
            response?.data.availabilityResponse.futureAvailabilities !== null
              ? response?.data.availabilityResponse.futureAvailabilities
              : [],
          );
          setRequestsData(
            response?.data.availabilityRequestResponse !== null
              ? response?.data.availabilityRequestResponse.availabilityRequests
              : [],
          );
        } else {
          analytics?.trackEvent({
            name: `Error: Get availability`,
            properties: {
              page: "Availability",
              workflow: "View",
              component: "Availability",
            },
          });
          setHasError(true);
        }
      })
      .catch((e: any) => {
        analytics?.trackEvent({
          name: `Error: Get availability`,
          properties: {
            page: "Availability",
            workflow: "View",
            component: "Availability",
            error: e,
          },
        });
        setHasError(true);
      });
  }, []);

  useEffect(() => {
    if (availabilityData) {
      setCurrentData(availabilityData?.currentAvailability);
      setFutureData(availabilityData?.futureAvailabilities);
    }

    if (availabilityRequestData) {
      setRequestsData(availabilityRequestData?.availabilityRequests);
    }
  }, [availabilityData, availabilityRequestData]);

  useEffect(() => {
    const currentAvailabilityArray = [currentData];
    const futureAvailabilityArray = futureData;

    let currentWRAExists = false;

    let currentWRANoEndDateExists = false;
    let futureWRANoEndDateExists = false;

    let weekData: any;
    if (currentAvailabilityArray) {
      for (weekData of currentAvailabilityArray) {
        if (weekData?.weeklyAvailabilityResponseList?.length > 1) {
          currentWRAExists = true;
          if (weekData?.endDate === null) {
            currentWRANoEndDateExists = true;
            break;
          }
        }
      }
    }

    let futureWeekData: any;
    if (futureAvailabilityArray) {
      for (futureWeekData of futureAvailabilityArray) {
        if (futureWeekData?.weeklyAvailabilityResponseList?.length > 1) {
          if (futureWeekData?.endDate === null) {
            futureWRANoEndDateExists = true;
            break;
          }
        }
      }
    }

    if (currentWRAExists && currentWRANoEndDateExists) {
      setIsWeeklyRotationalAvailabilityExists(true);
    }
    if (!currentWRANoEndDateExists && futureWRANoEndDateExists) {
      setIsWeeklyRotationalAvailabilityExists(true);
    }
  }, [currentData, futureData]);

  const toggleDrawer = (open: boolean) => {
    analytics?.trackEvent({
      name: `Toggled ${open ? "open" : "closed"}`,
      properties: {
        page: "Availability",
        workflow: "View",
        component: "Drawer",
      },
    });
    setOpenDrawer(open);
  };

  return (
    <>
      {hasError ? (
        <ErrorPage />
      ) : loading && onHomeScreen ? (
        <Loader />
      ) : (
        <Grid
          container
          item
          xs={12}
          className="parallel-grid availability-grid"
        >
          <Grid item xs={12} md={6} className="first-grid">
            <Current currentData={currentData} current={true} />
            <Future futureData={futureData} current={false} />
          </Grid>

          <Grid item xs={12} md={6} className={"last-grid"}>
            <Request requestsData={requestsData} loading={loading} />
            {windowWidth >= smallDesktop && !loading && (
              <CustomBtn
                startIcon={<Add />}
                text={translate("AVAILABILITY_REQUEST")}
                btnClass={"primary-btn"}
                onClick={() => {
                  dispatch(setEditPendingAvailability(false));
                  toggleDrawer(true);
                  dispatch(setIdealShifts(false));
                }}
                disabled={isWeeklyRotationalAvailabilityExists}
                aria-label={translate("CREATE_AVAILABILITY_BUTTON")}
              />
            )}
            {isWeeklyRotationalAvailabilityExists && (
              <Stack className="error-block">
                <div style={{ display: "flex" }}>
                  <img src={Warning} alt={"error"} className="warning-icon" />
                  <div className="error-div">
                    <Typography
                      id={"error-message-availability-title"}
                      variant="body1"
                    >
                      {translate("UNAVAILABLE")}
                    </Typography>
                    <Typography id={"error-message-availability-subtitle"}>
                      {translate("WEEKLY_ROTATION_AVAILABLE_CURRENTWEEK_ERROR")}
                    </Typography>
                  </div>
                </div>
              </Stack>
            )}
          </Grid>

          {windowWidth < smallDesktop && (
            <CustomBtn
              startIcon={<Add />}
              text={translate("AVAILABILITY_REQUEST")}
              btnClass={"primary-btn"}
              onClick={() => {
                dispatch(setEditPendingAvailability(false));
                toggleDrawer(true);
                dispatch(setIdealShifts(false));
              }}
              disabled={isWeeklyRotationalAvailabilityExists}
              aria-label={translate("CREATE_AVAILABILITY_BUTTON")}
            />
          )}

          {openDrawer && <Create toggleDrawer={toggleDrawer} />}
        </Grid>
      )}
    </>
  );
};

export default Availability;
