import apiService from "./apiService";

class UserStoreService {
  async getData(userId: any): Promise<any> {
    return await apiService.get(`/user/user-profile?userId=${userId}`);
  }

  async getStoreHours(input: {
    date: string;
    locationID: string;
  }): Promise<any> {
    return await apiService.get(
      `/location/${input.locationID}/hoo/${input.date}`,
    );
  }
}

export default UserStoreService;
