import { Grid, Stack } from "@mui/material";
import TypoGraphy from "../../common/Typography";
import "./styles.scss";
import CustomDialog from "../../common/Dialog";
import { useTranslation } from "react-i18next";

interface Props {
  openLangModal?: any;
  closeModal?: () => void;
  activeLang?: string;
  languageOptions?: any;
  handleLanguageChange?: any;
}

const LanguageModal: React.FC<Props> = ({
  openLangModal,
  closeModal,
  activeLang,
  languageOptions,
  handleLanguageChange,
}) => {
  const { t: translate } = useTranslation();

  return (
    <CustomDialog
      title={translate("LANGUAGE_UPDATE")}
      dialogClass="language-dialog confirm-dialog"
      open={openLangModal}
      onCancel={closeModal}
      onConfirm={handleLanguageChange}
      confirmText={translate("CONFIRM")}
      cancelText={translate("CANCEL")}
      confirmBtnClass={"secondary-btn"}
      cancelBtnClass={"cancel-btn"}
      contentClass="dialog-content"
    >
      <Grid item xs={12}>
        <Stack>
          <TypoGraphy variant="h2" component={"h2"}>
            {activeLang === languageOptions[0]
              ? translate(`${languageOptions[1].toUpperCase()}_LABEL`)
              : translate(`${languageOptions[0].toUpperCase()}_LABEL`)}
          </TypoGraphy>
        </Stack>
      </Grid>
    </CustomDialog>
  );
};

export default LanguageModal;
