interface RequestOptions {
  method: string;
  headers?: Headers;
  body?: string | FormData;
}

/**
 * Provides authentication headers and standardized response handling for all fetch calls.
 * Link to API documentation -- https://docs.starbucks.com/pages/viewpage.action?spaceKey=WFM&title=API+Design+and+Definition
 */
class ApiService {
  /**
   * @param apiUrl Fully qualified URL of the endpoint, including base and route.
   * @param options Request options
   * @returns Promise
   */
  private async handleRequest(
    // TODO: Remove the need to send the base URL and only require URL path.
    // This is a temporary change. post will be the final method after all the API changes are completed.
    // postAvailability method points to v2 URL and post method below points to v1 URL.
    // The v2 change is temporary and will be tested in DEV and ST until all API changes are completed after which it will all point to v2.
    apiUrl: string,
    options: RequestOptions,
  ): Promise<Response> {
    const empId = localStorage.getItem("userEmployeeId") ?? "";
    let tokenData = localStorage.getItem("ROCP_idToken") ?? "";
    tokenData = tokenData.replace(/^"(.*)"$/, "$1");

    const userAuthData = {
      employeeId: empId?.length !== 0 && empId,
    };

    const authObject = "employeeId: " + userAuthData.employeeId;

    const jsonString = JSON.stringify(authObject);
    const base64String = btoa(jsonString);

    const defaultHeaders = new Headers({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "auth-token":
        process.env.REACT_APP_IS_SSO_ENABLED === "true"
          ? tokenData
          : base64String,
    });

    const requestOptions: RequestOptions = {
      ...options,
      headers: new Headers(options.headers ?? defaultHeaders),
    };

    // Call fetch and handle all error cases
    try {
      const response = await fetch(apiUrl, requestOptions);
      const result = await response.json();

      if (response.ok) {
        return result;
      }

      const responseError = {
        type: "Error",
        message: result.message || "Network error occurred. Please try again.",
        data: result.data || "",
        code: result.code || "",
      };

      let error = new Error();
      error = { ...error, ...responseError };
      throw error;
    } catch (error) {
      console.error(
        `Error occurred during ${options.method} fetch of ${apiUrl}:`,
        error,
      );
      return Promise.reject(new Error(`Unexpected error`));
    }
  }

  public async get(url: string): Promise<any> {
    return await this.handleRequest(process.env.REACT_APP_BASE_URL + url, {
      method: "GET",
    });
  }

  public async post(url: string, data: any): Promise<any> {
    return await this.handleRequest(process.env.REACT_APP_BASE_URL + url, {
      method: "POST",
      body: JSON.stringify(data),
    });
  }

  public async postAvailability(url: string, data: any): Promise<any> {
    return await this.handleRequest(
      process.env.REACT_APP_POST_APP_BASE_URL + url,
      {
        method: "POST",
        body: JSON.stringify(data),
      },
    );
  }

  public async update(url: string, data: any): Promise<any> {
    return await this.handleRequest(process.env.REACT_APP_BASE_URL + url, {
      method: "PUT",
      body: JSON.stringify(data),
    });
  }

  public async updateAvailability(url: string, data: any): Promise<any> {
    return await this.handleRequest(
      process.env.REACT_APP_POST_APP_BASE_URL + url,
      {
        method: "PUT",
        body: JSON.stringify(data),
      },
    );
  }

  public async delete(url: string): Promise<any> {
    return await this.handleRequest(process.env.REACT_APP_BASE_URL + url, {
      method: "DELETE",
    });
  }
}

export default new ApiService();
