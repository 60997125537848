import Drawer from "@mui/material/Drawer";
import { Box, Grid } from "@mui/material";
import "simplebar-react/dist/simplebar.min.css";
import "./style.scss";
import useViewportWidth from "../../../utils/useViewportWidth";
import { smallDesktop } from "../../GlobalConstants";
import { useTranslation } from "react-i18next";
import { PreferenceEntryToEdit } from "../../pages/Preferences/Create";
import Loader from "../../shared/Loader";
import { useAppSelector } from "../../../redux/store";
import { useEffect, useState } from "react";

interface Props {
  anchor: "top" | "left" | "bottom" | "right";
  toggleDrawer: (
    open: boolean,
    preferenceEntryToEdit?: PreferenceEntryToEdit,
  ) => void;
  drawerClass?: string;
  closeBtnClass?: string;
  drawerTitle?: React.ReactNode;
  titleClass?: string;
  children?: React.ReactNode;
  onCustomAction?: () => void;
  page: string;
  preferenceError: any;
}

const CustomDrawer: React.FC<Props> = ({
  anchor,
  toggleDrawer,
  drawerClass,
  children,
  preferenceError,
  page,
}) => {
  const windowWidth = useViewportWidth();
  const [showLoader, setShowLoader] = useState<boolean>(true);
  const { t: translate } = useTranslation();
  const { success: preferenceSuccess } = useAppSelector(
    (state: any) => state.preferences,
  );

  useEffect(() => {
    if (
      (preferenceSuccess && !preferenceError) ||
      (!preferenceSuccess && preferenceError)
    ) {
      setShowLoader(false);
    }
  }, [preferenceError, preferenceSuccess]);

  return (
    <Drawer
      anchor={anchor}
      open={true}
      onClose={() => toggleDrawer(false)}
      className={drawerClass}
      role="dialog"
      aria-labelledby={translate("DRAWER_TITLE")}
    >
      {showLoader && page === "availabilityCreate" ? (
        <div style={{ margin: "auto" }}>
          <Loader size={"3rem"} fullScreen={false} />
        </div>
      ) : (
        <Box role="presentation">
          {windowWidth < smallDesktop ? (
            <div className="drawer-scroll" tabIndex={-1}>
              <Grid container item xs={12}>
                {children}
              </Grid>
            </div>
          ) : (
            <Grid container item xs={12}>
              {children}
            </Grid>
          )}
        </Box>
      )}
    </Drawer>
  );
};

export default CustomDrawer;
