import { createSlice } from "@reduxjs/toolkit";

export interface metaState {
  retryAttemptAvailability: number;
  isAvailabilityApiFinalError: boolean;
  onHomeScreen: boolean;
  retryAttemptPreferences: number;
  infoClicked: boolean;
  isNewUser: boolean;
  editPendingAvailability: boolean;
  isSetIdealShifts: boolean;
  weekDayIdealShift: string;
  editPendingAvailabilityData: any;
  times: any;
  preferredHoursFor150compliance: number;
}

const initialState: metaState = {
  retryAttemptAvailability: 0,
  isAvailabilityApiFinalError: false,
  onHomeScreen: false,
  retryAttemptPreferences: 0,
  infoClicked: false,
  isNewUser: false,
  editPendingAvailability: false,
  editPendingAvailabilityData: [],
  isSetIdealShifts: false,
  weekDayIdealShift: "",
  times: [],
  preferredHoursFor150compliance: 0,
};

export const metaSlice = createSlice({
  name: "meta",
  initialState: initialState,
  reducers: {
    setRetryAttemptAvailability: (state, action) => {
      state.retryAttemptAvailability = action.payload;
    },
    setIsAvailabilityApiFinalError: (state, action) => {
      state.isAvailabilityApiFinalError = action.payload;
    },
    setOnHomeScreen: (state, action) => {
      state.onHomeScreen = action.payload;
    },
    setRetryAttemptPreferences: (state, action) => {
      state.retryAttemptPreferences = action.payload;
    },
    setInfoClicked: (state, action) => {
      state.infoClicked = action.payload;
    },
    setIsNewUser: (state, action) => {
      state.isNewUser = action.payload;
    },
    setEditPendingAvailability: (state, action) => {
      state.editPendingAvailability = action.payload;
    },
    setIdealShifts: (state, action) => {
      state.isSetIdealShifts = action.payload;
    },
    setWeekDayForIdealShift: (state, action) => {
      state.weekDayIdealShift = action.payload;
    },
    setEditPendingAvailabilityData: (state, action) => {
      state.editPendingAvailabilityData = action.payload;
    },
    setGeneralAvailabilityTimes: (state, action) => {
      state.times = action.payload;
    },
    setPreferredHoursFor150compliance: (state, action) => {
      state.times = action.payload;
    },
  },
});

export const {
  setRetryAttemptAvailability,
  setIsAvailabilityApiFinalError,
  setOnHomeScreen,
  setRetryAttemptPreferences,
  setInfoClicked,
  setIsNewUser,
  setEditPendingAvailability,
  setEditPendingAvailabilityData,
  setIdealShifts,
  setWeekDayForIdealShift,
  setGeneralAvailabilityTimes,
  setPreferredHoursFor150compliance,
} = metaSlice.actions;
export default metaSlice.reducer;
