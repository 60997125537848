import { useAppDispatch } from "../../../../redux/store";
import RequestWrapper from "./RequestWrapper";
import { setCopyCurrentAvailability } from "../../../../features/copyAvailabilityReducer";

interface Props {
  comment: string;
  setComment: (comment: string) => void;
  weeklyDays: any;
  setWeeklyDays: (weeklyDays: any) => void;
  totalHours: any;
  setTotalHours: (totalHours: any) => void;
}

const WeeklyAvailabilityRequest: React.FC<Props> = ({
  comment,
  setComment,
  weeklyDays,
  setWeeklyDays,
  totalHours,
  setTotalHours,
}) => {
  const dispatch = useAppDispatch();

  const handleCopyRequest = (checked: boolean) => {
    dispatch(setCopyCurrentAvailability(checked));
  };

  return (
    <RequestWrapper
      handleCopyRequest={handleCopyRequest}
      comment={comment}
      setComment={setComment}
      weeklyDays={weeklyDays}
      setWeeklyDays={setWeeklyDays}
      updateComment={setComment}
      totalHours={totalHours}
      setTotalHours={setTotalHours}
    />
  );
};

export default WeeklyAvailabilityRequest;
