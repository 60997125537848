import apiService from "./apiService";

class AvailabilityService {
  async getAvailabilityData(param: any): Promise<any> {
    return await apiService.get(`/partner/availability?partnerId=${param}`);
  }

  async getRequestData(param: any): Promise<any> {
    return await apiService.get(
      `/partner/availability-requests?partnerId=${param}`,
    );
  }

  async getAllAvailabilityData(param: any): Promise<any> {
    const response: any = await apiService.get(
      `/partner/availability/all?partnerId=${param}`,
    );
    if (response && response.errorResponse === null) {
      const updatedPartnerId =
        response.data?.availabilityResponse.partnerId ?? "";
      localStorage.setItem("userEmployeeId", updatedPartnerId);
      return response;
    }
  }

  async createRequestData(el: any): Promise<any> {
    const userEmployeeId = localStorage.getItem("userEmployeeId");

    return await apiService.postAvailability(
      `/partner/availability-request?partnerId=${userEmployeeId}`,
      el,
    );
  }

  async createAvailabilityData(el: any): Promise<any> {
    return await apiService.post("/partner/availability", el);
  }

  async deleteRequestData(el: any): Promise<any> {
    const userEmployeeId = localStorage.getItem("userEmployeeId");
    return await apiService.updateAvailability(
      `/partner/availability-request?partnerId=${userEmployeeId}`,
      el,
    );
  }

  async updateRequestData(el: any): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    return await apiService.updateAvailability(
      `/partner/availability-request?partnerId=${partnerId}`,
      el,
    );
  }
}

export default AvailabilityService;
