import { createAsyncThunk } from "@reduxjs/toolkit";
import AvailabilityService from "../../services/availabilityService";
const AvailService: AvailabilityService = new AvailabilityService();
export const getAvailabilityAll = createAsyncThunk(
  `availabilityAll/getData`,
  async () => {
    const userEmployeeId = localStorage.getItem("userEmployeeId");
    const res = await AvailService.getAllAvailabilityData(userEmployeeId);
    return res;
  },
);
