import { createAsyncThunk } from "@reduxjs/toolkit";
import preferencesService from "../../services/preferencesService";
import { PreferItem } from "../../components/pages/Preferences/model";

const PrefService: preferencesService = new preferencesService();

export const getPreferences = createAsyncThunk(
  `preferences/getData`,
  async () => {
    const res = await PrefService.getData();
    return res;
  },
);

export const getPreferencesAttribute = createAsyncThunk(
  `preferences/getAttributeData`,
  async () => {
    const res = await PrefService.getAttributeData();
    return res;
  },
);

export const createPreference = createAsyncThunk(
  `preferences/createData`,
  async (input: PreferItem) => {
    const res = await PrefService.createData(input);
    return res;
  },
);

export const updatePreference = createAsyncThunk(
  `preferences/updateData`,
  async (input: PreferItem) => {
    const res = await PrefService.updateData(input);
    return res;
  },
);

export const refreshPreference = createAsyncThunk(
  `preferences/refreshData`,
  (errorState: any) => {
    return errorState;
  },
);
