import { createAsyncThunk } from "@reduxjs/toolkit";
import availabilityRequestsService from "../../services/availabilityService";

const AvailRequestsService: availabilityRequestsService =
  new availabilityRequestsService();

export const getAvailabilityRequest = createAsyncThunk(
  `availabilityRequests/getData`,
  async () => {
    const employeeId = localStorage.getItem("userEmployeeId");
    const res = await AvailRequestsService.getRequestData(employeeId);
    return res;
  },
);

export const makeAvailabilityRequest = createAsyncThunk(
  `availabilityRequests/createData`,
  async (CreateRequestParam: any) => {
    const res =
      await AvailRequestsService.createRequestData(CreateRequestParam);
    return res;
  },
);

export const updateAvailabilityRequest = createAsyncThunk(
  `availabilityRequests/updateData`,
  async (CreateRequestParam: any) => {
    const res =
      await AvailRequestsService.updateRequestData(CreateRequestParam);
    return res;
  },
);

export const deleteAvailabilityRequest = createAsyncThunk(
  `availabilityRequests/deleteData`,
  async (CreateRequestParam: any) => {
    const res =
      await AvailRequestsService.deleteRequestData(CreateRequestParam);
    return res;
  },
);
