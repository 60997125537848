import { createAsyncThunk } from "@reduxjs/toolkit";
import UserStoreService from "../../services/userStoreService";
const StoreService: UserStoreService = new UserStoreService();
export const getUserStoreData = createAsyncThunk(
  `userStore/getData`,
  async (input: number) => {
    const res = await StoreService.getData(input);
    return res;
  },
);

export const getUserStoreHours = createAsyncThunk(
  `userStore/getStoreHours`,
  async (input: { date: string; locationID: string }) => {
    const res = await StoreService.getStoreHours(input);
    return res;
  },
);
