import { createAsyncThunk } from "@reduxjs/toolkit";
import availabilityService from "../../services/availabilityService";
const AvailService: availabilityService = new availabilityService();
export const getAvailability = createAsyncThunk(
  `availability/getData`,
  async () => {
    const employeeId = localStorage.getItem("userEmployeeId");
    const res = await AvailService.getAvailabilityData(employeeId);
    return res;
  },
);

export const createAvailability = createAsyncThunk(
  `availability/createData`,
  async (input) => {
    const res = await AvailService.createAvailabilityData(input);
    return res;
  },
);
