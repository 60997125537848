import React, { useContext, useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import TypoGraphy from "../../../common/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { DateCalendar, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { YearCalendar } from "@mui/x-date-pickers/YearCalendar";
import updateLocale from "dayjs/plugin/updateLocale";
import moment from "moment";
import "../../../../styles/calendar.scss";
import { useAppSelector } from "../../../../redux/store";
import "dayjs/locale/fr";
import WarningTip from "../../Availability/Create/WarningTip";
import { AnalyticsContext } from "../../../../analytics";
import CalendarIconEnabled from "../../../../styles/assets/svg/calendar.svg";
import { PREFERENCE_MAXIMUM_ALLOW_DAYS } from "../../../GlobalConstants";

interface Props {
  showCalendar: boolean;
  setShowCalendar: (setShowCalendar: boolean) => void;
  startDate?: string;
  handleDateChange: (e: any) => void;
  onStartDateDisabled: (disabled: boolean) => void;
  isSelectedDayMonday: boolean;
  setIsSelectedDayMonday: (setIsSelectedDayMonday: any) => void;
  isStartDateExist?: boolean;
  lastPreferredDate?: string;
}

const DatePick: React.FC<Props> = ({
  showCalendar,
  setShowCalendar,
  startDate,
  handleDateChange: propHandleStartDate,
  onStartDateDisabled,
  isSelectedDayMonday,
  setIsSelectedDayMonday,
  isStartDateExist,
  lastPreferredDate,
}) => {
  const { t: translate, i18n } = useTranslation();
  const analytics = useContext(AnalyticsContext);
  const [showYearCalendar, setShowYearCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [currentYearSelected, setCurrentYearSelected] = useState<any>(null);
  dayjs.locale(i18n.language?.toLowerCase());
  dayjs.extend(updateLocale);

  const { preferencesData } = useAppSelector((state: any) => state.preferences);
  const allowFromDays = `${process.env.REACT_APP_NEW_PREFERENCE_ALLOW_FROM_DAYS}`;
  const denyAfterDays = `${process.env.REACT_APP_NEW_PREFERENCE_DENY_AFTER_DAYS}`;
  const today = moment(new Date()).format();
  const allowDate = moment(today).add(allowFromDays, "days").format();
  const arrSelectedDate: any[] = [];

  dayjs.updateLocale("en", {
    weekStart: 1,
  });

  useEffect(() => {
    if (!showCalendar) {
      setShowYearCalendar(false);
    }
  }, [showCalendar]);

  setTimeout(() => {
    const buttons = document?.querySelectorAll(
      ".MuiIconButton-edgeStart, .MuiIconButton-edgeEnd",
    );
    buttons?.forEach((button) => {
      button?.removeAttribute("title");
    });
  }, 100);

  const shouldDisableDate = (date: Date | null) => {
    const allowFrom =
      lastPreferredDate !== "" &&
      moment(lastPreferredDate).isSameOrAfter(allowDate)
        ? moment(lastPreferredDate).add(6, "days").format("YYYY-MM-DD")
        : moment(today)
            .add(allowFromDays, "days")
            .isoWeekday(1)
            .format("YYYY-MM-DD");

    const lastDay = moment().add(Number(denyAfterDays), "days").format();
    const isMonday = dayjs(date).day() === 1;

    if (
      isMonday &&
      (dayjs(date).isBefore(allowFrom, "day") ||
        dayjs(date).isSame(lastPreferredDate, "day") ||
        dayjs(date).isAfter(lastDay, "day"))
    ) {
      return true;
    }
    return false;
  };

  const handleMonday = (
    isTodayMonday: boolean,
    allowDate: any,
    setSelectedDate: any,
  ) => {
    if (isTodayMonday) {
      // If today is Monday then take the allowDate which is 28 days and will be a Monday.
      setSelectedDate(dayjs(allowDate));
    } else {
      // If the date is not Monday after calculating 28 days then we are picking the current Week's Monday.
      const sameWeekMonday = moment(allowDate)
        .isoWeekday(1)
        .format("YYYY-MM-DD");
      setSelectedDate(dayjs(sameWeekMonday));
    }
  };

  useEffect(() => {
    const isTodayMonday = moment(today).day() === 1 ? true : false;
    const currentYear = dayjs(today).get("y");
    if (currentYearSelected === null) {
      setCurrentYearSelected(currentYear);
    }
    // calculating the current week Monday
    const currentWeekMonday =
      moment(today).isoWeekday() <= 1
        ? moment(today).isoWeekday(1).format()
        : moment(today).day(1).format();

    if (
      preferencesData &&
      Object.keys(preferencesData).length !== 0 &&
      preferencesData.currentPreferredHours &&
      preferencesData.futurePreferredHours
    ) {
      if (
        Object.keys(preferencesData.currentPreferredHours).length === 0 &&
        preferencesData.futurePreferredHours.length === 0
      ) {
        // In this case, the date from which new preference can be created is 28 days from today.
        handleMonday(isTodayMonday, allowDate, setSelectedDate);
      } else if (
        Object.keys(preferencesData.currentPreferredHours).length !== 0 &&
        preferencesData.futurePreferredHours.length === 0
      ) {
        const currentObj = preferencesData.currentPreferredHours;
        // Checking if the current preference can be edited
        const isEditingEnabled =
          currentObj?.end === null &&
          (currentObj?.minHoursPerWeek === 0 ||
            currentObj?.maxHoursPerWeek === 0 ||
            currentObj?.preferredHoursPerWeek === 0);

        if (isEditingEnabled) {
          // Considering the current week Monday as the next allowed preference create date
          const allowDate = moment(currentWeekMonday).format("YYYY-MM-DD");
          setSelectedDate(dayjs(allowDate));
        } else {
          // If editing is not enabled, then the allowed date will be 28 days from today
          handleMonday(isTodayMonday, allowDate, setSelectedDate);
        }
      } else if (preferencesData.futurePreferredHours.length !== 0) {
        // In case of future preferencesData, first sorting and fetching the latest future preference
        const sortedFutureArr = preferencesData.futurePreferredHours
          ?.slice()
          .sort((a: any, b: any) => moment(a.start).diff(moment(b.start)));
        const latestFutureObjStartDate = moment(
          sortedFutureArr[sortedFutureArr.length - 1].start,
        );
        // calculating the difference of days between latest future date and today.
        const daysBetweenTodayAndFutureStartDate = moment(
          latestFutureObjStartDate,
        ).diff(moment(new Date()).format("YYYY-MM-DD"), "days");

        if (daysBetweenTodayAndFutureStartDate >= 28) {
          //If difference is greater than or equal to 28 days allowed date will be immediate nextMonday
          const mondayNextFutureStartDate = moment(latestFutureObjStartDate)
            .add(7, "days")
            .startOf("isoWeek");

          if (
            mondayNextFutureStartDate.diff(moment(new Date()), "days") <
            PREFERENCE_MAXIMUM_ALLOW_DAYS
          ) {
            const eligibleDate = moment(mondayNextFutureStartDate).format(
              "YYYY-MM-DD",
            );
            setSelectedDate(dayjs(eligibleDate));
          }
        } else {
          //if difference is less than 28 then allowed date will be 28 days from today but if it comes same as latest preferred date, then following Monday
          if (
            moment(allowDate)
              .startOf("isoWeek")
              .isSame(latestFutureObjStartDate)
          ) {
            const eligibleDate = moment(allowDate)
              .startOf("isoWeek")
              .add(7, "days")
              .format("YYYY-MM-DD");
            setSelectedDate(dayjs(eligibleDate));
          } else {
            const eligibleDate = moment(allowDate)
              .startOf("isoWeek")
              .format("YYYY-MM-DD");
            setSelectedDate(dayjs(eligibleDate));
          }
        }
      }
    }
  }, [currentYearSelected]);

  const handleDateChange = (startDate: Date | null) => {
    const selectedDate = dayjs(startDate)?.startOf("day");
    const isMonday = selectedDate?.day() === 1;

    if (startDate) {
      if (!isMonday) {
        onStartDateDisabled(true);
        setIsSelectedDayMonday(true);
      } else {
        onStartDateDisabled(false);
        setIsSelectedDayMonday(false);
        propHandleStartDate(startDate);
        setShowCalendar(false);
      }
    }
  };

  const handleYearMonthViewOpen = () => {
    if (showYearCalendar || !showYearCalendar) {
      setIsSelectedDayMonday(false);
      setShowYearCalendar((prevShowYearCalendar) => !prevShowYearCalendar);
    }
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    setIsSelectedDayMonday(false);
    let isValidDate = false;
    const newSelectedDayIsPastDayOrNot = dayjs(date)
      ?.startOf("day")
      .isBefore(dayjs()?.startOf("day"), "day");
    const newSelectedDateIsAfterPermittedDateOrNot = dayjs(date).isAfter(
      dayjs(new Date()).add(60, "days"),
    );
    // eslint-disable-next-line
    if (date?.isSame(allowDate) || date?.isAfter(allowDate)) {
      if (
        shouldDisableDate(dayjs(date).toDate()) === false &&
        (date?.day() === 1) === true
      ) {
        isValidDate = true;
      }
    }
    if (
      newSelectedDayIsPastDayOrNot ||
      newSelectedDateIsAfterPermittedDateOrNot ||
      !isValidDate
    ) {
      handleDateChange(null);
    } else {
      handleDateChange(date as Date | null);
      setSelectedDate(date);
    }
  };

  const handleNextYearChange = (date: dayjs.Dayjs | null) => {
    setIsSelectedDayMonday(false);
    const yearToBeChanged = date?.get("y");
    const today = dayjs(new Date());
    const permissibleDateLimit = dayjs(today).add(60, "days");
    const currentYear = today.get("y");
    if (currentYear === yearToBeChanged) {
      setCurrentYearSelected(currentYear);
      const date = arrSelectedDate.filter((item) => item === currentYear);
      setSelectedDate(date[0]);
    }

    if (permissibleDateLimit.get("y") === yearToBeChanged) {
      const mondays: any[] = [];
      let firstDayOfYear = date?.startOf("year");

      while (
        dayjs(firstDayOfYear).isBefore(dayjs(permissibleDateLimit)) ||
        dayjs(firstDayOfYear).isSame(dayjs(permissibleDateLimit))
      ) {
        firstDayOfYear = firstDayOfYear?.add(1, "day");
        if (dayjs(firstDayOfYear).day() === 1) {
          mondays.push(firstDayOfYear);
        }
      }
      for (const i of mondays) {
        if (shouldDisableDate(mondays[i]) === false) {
          setSelectedDate(mondays[i]);
          return;
        }
      }
    }
  };

  const strikeDays = ({ day, ...props }: PickersDayProps<Date>) => {
    const today = dayjs()?.startOf("day");
    const formattedDay = dayjs(day)?.date();
    const isAfterToday =
      dayjs(day)?.isAfter(today, "day") || dayjs(day)?.isSame(today, "day");

    const dayElement = (
      <PickersDay {...props} day={day}>
        {isAfterToday ? <span>{formattedDay}</span> : formattedDay}
      </PickersDay>
    );
    return dayElement;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      analytics?.trackEvent({
        name: `Close calendar enter key`,
        properties: {
          page: "Preferences",
          workflow: "Create",
          component: "Date picker background",
        },
      });
      !isStartDateExist && setShowCalendar(!showCalendar);
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Stack
          className={showCalendar ? "date-wrapper active" : "date-wrapper"}
          onClick={() => {
            analytics?.trackEvent({
              name: `Close calendar click`,
              properties: {
                page: "Preferences",
                workflow: "Create",
                component: "Date picker background",
              },
            });
            !isStartDateExist && setShowCalendar(!showCalendar);
          }}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
          aria-label={translate("CHOOSE_START_DATE")}
          alignItems={"center"}
          direction={"row"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"}>
            <TypoGraphy typeClass="light-font mr-half" variant="body1">
              {translate("START")}
            </TypoGraphy>
            <TypoGraphy variant="body1" typeClass="semi-bold-font">
              {startDate && isStartDateExist
                ? moment
                    .utc(startDate)
                    ?.format(
                      i18n.language?.toLowerCase() === "fr"
                        ? "YYYY-MM-DD"
                        : "MMM DD, YYYY",
                    )
                : startDate &&
                  !isStartDateExist &&
                  dayjs(startDate)?.format(
                    i18n.language?.toLowerCase() === "fr"
                      ? "YYYY-MM-DD"
                      : "MMM DD, YYYY",
                  )}
            </TypoGraphy>
          </Stack>
          <Stack textAlign={"right"}>
            <img
              id="calendarMonthIcon"
              src={CalendarIconEnabled}
              alt={"calendar"}
              className="warning-icon"
            />
          </Stack>
        </Stack>
      </Grid>
      {showCalendar && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack
            className={
              isSelectedDayMonday
                ? "date-calendar right-cal hasError3 date-calendar right-cal preference-cal"
                : "date-calendar right-cal preference-cal"
            }
          >
            <DateCalendar
              disablePast={true}
              onChange={handleStartDateChange}
              dayOfWeekFormatter={(_day, weekday) =>
                dayjs(weekday)
                  .locale(i18n.language.toLowerCase())
                  .format("ddd")
                  .toUpperCase()
                  .replace(/\./g, "")
              }
              shouldDisableDate={shouldDisableDate}
              views={["day", "month"]}
              slots={{ day: strikeDays }}
              onViewChange={handleYearMonthViewOpen}
              value={selectedDate}
            />
            {showYearCalendar && (
              <YearCalendar
                minDate={dayjs().startOf("day")}
                maxDate={dayjs().add(2, "year")}
                onChange={handleNextYearChange}
                value={selectedDate ?? null}
              />
            )}
            {isSelectedDayMonday && (
              <WarningTip message={translate("START_DATE_MUST_BE_A_MONDAY")} />
            )}
          </Stack>
        </LocalizationProvider>
      )}
    </>
  );
};

export default DatePick;
