import apiService from "./apiService";

class PreferencesService {
  async getData(): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    return await apiService.get(
      `/partner/preferred-hours?partnerId=${partnerId}`,
    );
  }

  async getAttributeData(): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    return await apiService.get(
      `/partner/employee/attributeResponse?partnerId=${partnerId}`,
    );
  }

  async createData(el: any): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    return await apiService.post(
      `/partner/preferred-hours?partnerId=${partnerId}`,
      el,
    );
  }

  async updateData(el: any): Promise<any> {
    const partnerId = localStorage.getItem("userEmployeeId");
    return await apiService.update(
      `/partner/preferred-hours?partnerId=${partnerId}`,
      el,
    );
  }
}

export default PreferencesService;
