import React from "react";
import { Grid } from "@mui/material";
import CustomAccordion from "../../../../common/Accordion";
import RequestAccContent from "../RequestAccContent";
import { getTotalHrs } from "../../../../../utils/durationCalculate";
import RequestAccHeader from "../RequestAccHeader";
import { useTranslation } from "react-i18next";

interface Props {
  genAvailData: any[];
  expandedId: number | null;
  onAccordionChange: (id: number) => void;
}

const ExpiredRequests: React.FC<Props> = ({
  genAvailData,
  expandedId,
  onAccordionChange,
}) => {
  const { t: translate } = useTranslation();
  genAvailData?.sort(
    (a, b) =>
      new Date(a.effectiveFrom).getTime() - new Date(b.effectiveFrom).getTime(),
  );

  return (
    <Grid item xs={12}>
      {genAvailData?.map((availData: any, index: number) => (
        <CustomAccordion
          key={index}
          iconClass={"acc-icon"}
          accordionClass={"acc-wrapper acc-requests expired-requests"}
          headClass={"acc-header"}
          contentClass={"acc-content"}
          headContent={
            <RequestAccHeader
              averageHrs={getTotalHrs(availData?.generalAvailability)}
              startDate={availData?.effectiveFrom}
              endDate={availData?.endsAfter}
              statusText={translate("EXPIRED")}
              statusClass={"expired"}
            />
          }
          details={
            <RequestAccContent
              generalAvailability={availData?.generalAvailability}
              comments={availData?.comments}
              requestStatus={availData?.status}
            />
          }
          expanded={expandedId === availData.id}
          onChange={() => onAccordionChange(availData.id)}
        />
      ))}
    </Grid>
  );
};

export default ExpiredRequests;
